<template>
  <div>
    <nav-bar></nav-bar>
    <div class="banner">
    </div>
    <section>
      <div>
        <Title :title="'企业动态'"></Title>
        <div>
          <div
            class="item pointer flex wow bounceIn"
            v-for="(item, index) in info"
            :key="index"
          >
            <img :src="item.url" alt=""  />
            <div >
              <div class="title flex">
                <div class="date">
                  <div>9</div>
                  <div>2020-12</div>
                </div>
                {{ item.title }}
              </div>
              <div class="describe ellipsis3">{{ item.describe }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footbar></footbar>
  </div>
</template>
<script>
import NavBar from "@/components/nav/index";
import footbar from "@/components/footbar/index";
import { WOW } from "wowjs";
export default {
  name: "about",
  components: { NavBar, footbar },
  data() {
    return {
      info: [
        {
          url: require('@/assets/images/news/1.png'),
          title: "优秀科技人才编辑培养提高",
          describe:
            "政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益",
        },
        {
          url: require('@/assets/images/news/2.png'),
          title: "市科技局多举措支持企业创新",
          describe:
            "政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益",
        },
        {
          url: require('@/assets/images/news/3.png'),
          title: "关于小微企业这里有个好消息",
          describe:
            "政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益",
        },
        {
          url: require('@/assets/images/news/4.png'),
          title: "坚决反对美国政府无端打压中国政府",
          describe:
            "政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益",
        },
        {
          url: require('@/assets/images/news/5.png'),
          title: "中国企业书写更多共赢故事",
          describe:
            "政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益",
        },
        {
          url: require('@/assets/images/news/6.png'),
          title: "拉萨市实现ODR企业零突破",
          describe:
            "政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益政府采购法律服务之“3.15”消费者权益保护公益活动政府采购法律服务    3.15”消费者权益保护公益活动消费者权益保护公益",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 431px;
  background-color: transparent;
  background-image: url("../assets/images/banner/news.png");
  /* background-size: 100% 100%; */
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: table;
}
.banner img {
  width: 100%;
}
section {
  padding: 21px 0 80px;
}
section > div {
  width: 1200px;
  margin: 0 auto;
}
.flex {
  flex-wrap: wrap;
}
.item {
  margin-top: 40px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 40px;
}
.item:last-child {
  border: none;
  padding-bottom: 0;
}
.item > img {
  width: 393px;
  height: 181px;
  margin-right: 20px;
}
.item > div {
  flex: 1;
}
.item .title {
  font-size: 16px;
  font-weight: 400;
  line-height: 89px;
  color: #666666;
  margin: 0 auto 18px;
  text-align: left;
}
.item .title > .date {
  width: 89px;
  height: 89px;
  background: #f4f4f4;
  margin-right: 10px;
  line-height: 1.2;
}
.item .title > .date > div:nth-child(1) {
  font-size: 30px;
  font-weight: 500;
  color: #666666;
  text-align: center;
  margin: 14px 0 0 0;
}
.item .title > .date > div:nth-child(2) {
  font-size: 16px;
  font-weight: 500;
  color: #999999;
  text-align: center;
}
.item .describe {
  width: 781px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #999999;
}
</style>
